import { initialize } from "./initialize";
import { COMPONENT_TYPES } from "./component-types";
var getComponent = function (component, elements) {
    switch (component) {
        case COMPONENT_TYPES.Navigation:
            return import(
            /* webpackChunkName: "navigation" */
            "@components/navigation/navigation").then(function (_a) {
                var navigation = _a.navigation;
                return initialize(navigation, {
                    elements: elements,
                });
            });
    }
};
export { getComponent };
