import { getComponent } from "./get-component";
var init = function (component) {
    var _a;
    var elements = document.querySelectorAll(".".concat(component));
    if ((elements === null || elements === void 0 ? void 0 : elements.length) < 1)
        return;
    (_a = getComponent(component, elements)) === null || _a === void 0 ? void 0 : _a.catch(function (error) {
        return console.log("Something went wrong while rendering component: ".concat(component, "\n\t\t\t"), error);
    });
};
var bootstrapper = function (components) {
    if (!components)
        return;
    var moduleList = Object.values(components);
    document.addEventListener("DOMContentLoaded", function () {
        return moduleList.forEach(function (module) { return init(module); });
    });
};
export { bootstrapper };
